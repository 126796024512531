import React, { useState, ReactElement } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useHistory,
} from "react-router-dom";
import { IconButton } from "./IconButton";
import { GalleryGrid } from "./GalleryGrid";
import { GalleryImage } from "./GalleryRow";

import { IconInstagram, IconHamburger, IconX } from "../icons";

export const ResizeObserver = (window as any).ResizeObserver;
export const MAX_WIDTH = 1440;
export const MOBILE_BREAK = 720;

import aboutPhoto from "../images/about.png";

/*
 * Landscape image descriptions
 */

const antelopeCanyon: GalleryImage = {
  src: require("../images/landscape/antelope-canyon.jpeg"),
  title: "Antelope Canyon",
  desc: "6x8 Oil on canvas panel",
};

const balancedRock: GalleryImage = {
  src: require("../images/landscape/balanced-rock.jpeg"),
  title: "Balanced Rock",
  desc: "6x8 Oil on canvas panel",
};

const boat: GalleryImage = {
  src: require("../images/landscape/boat.jpeg"),
  title: "Boat",
  desc: "6x8 Oil on canvas panel",
};

const lakePowell: GalleryImage = {
  src: require("../images/landscape/lake-powell.jpeg"),
  title: "Lake Powell",
  desc: "6x8 Oil on canvas panel",
};

const lion: GalleryImage = {
  src: require("../images/landscape/lion.jpeg"),
  title: "Lion Statue at Legion of Honor",
  desc: "11x14 Oil on canvas panel",
};

const moriPoint: GalleryImage = {
  src: require("../images/landscape/mori-point.jpeg"),
  title: "Mori Point",
  desc: "11x14 Oil on canvas panel",
};

const mossBeach: GalleryImage = {
  src: require("../images/landscape/moss-beach.jpeg"),
  title: "Plein Air at Moss Beach",
  desc: "6x8 Oil on canvas panel",
};

const mtDavidson: GalleryImage = {
  src: require("../images/landscape/mtdavidson.jpeg"),
  title: "Plein Air at Mt. Davidson",
  desc: "8x5 Oil on oil paper",
};

const rockawayBeach: GalleryImage = {
  src: require("../images/landscape/rockaway-beach.jpeg"),
  title: "Plein Air at Rockaway Beach",
  desc: "11x14 Oil on canvas panel",
};

const watermelons: GalleryImage = {
  src: require("../images/landscape/watermelons.jpeg"),
  title: "Watermelons",
  desc: "16x20 Oil on Framed Canvas",
};

/*
 * Still-life image descriptions
 */

const aromatics: GalleryImage = {
  src: require("../images/still-life/aromatics.jpeg"),
  title: "Aromatics",
  desc: "8x8 Oil on oil paper",
};

const cheezeIts: GalleryImage = {
  src: require("../images/still-life/cheeze-its.jpeg"),
  title: "Cheeze-Its",
  desc: "9x12 Oil on canvas panel",
};

const lemons: GalleryImage = {
  src: require("../images/still-life/lemons.jpeg"),
  title: "Lemons",
  desc: "8x11 Oil on oil paper",
};

const mushroom: GalleryImage = {
  src: require("../images/still-life/mushroom.jpeg"),
  title: "Mushroom",
  desc: "6x8 Oil on canvas panel",
};

const oranges: GalleryImage = {
  src: require("../images/still-life/oranges.jpeg"),
  title: "Oranges",
  desc: "9x7 Oil on oil paper",
};

const tissueBox: GalleryImage = {
  src: require("../images/still-life/tissue-box.jpeg"),
  title: "Tissue Box",
  desc: "8x8 Oil on oil paper",
};

const daisies: GalleryImage = {
  src: require("../images/still-life/daisies.jpeg"),
  title: "Daisies",
  desc: "8x10 Oil on panel",
};

const grapes: GalleryImage = {
  src: require("../images/still-life/grapes.jpeg"),
  title: "Grapes",
  desc: "11x14 Oil on panel",
};

const peach: GalleryImage = {
  src: require("../images/still-life/peach.jpeg"),
  title: "Peach",
  desc: "8x10 Oil on panel",
};

function About() {
  return (
    <>
      <div className="two-col-reverse">
        <div>
          <p>
            Hi, I'm Ryan! I'm a South African born oil painter living in San
            Francisco.
          </p>
          <p>&nbsp;</p>
          <p>
            I love finding beautiful (and sometimes not so obviously beautiful)
            things, and trying to capture them with paint.
          </p>
          <p>&nbsp;</p>
          <p>
            You can reach me on instagram at{" "}
            <a href="https://instagram.com/ryankpaints" target="_blank">
              @ryankpaints
            </a>
          </p>
        </div>
        <img className="width-30" src={aboutPhoto} />
      </div>
    </>
  );
}

function Nav(props: { className?: string }) {
  return (
    <nav className={props.className}>
      <ul>
        <li>
          <Link to="/landscape">Landscape</Link>
        </li>
        <li>
          <Link to="/still-life">Still Life</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <a href="https://instagram.com/ryankpaints" target="_blank">
            <IconButton>
              <IconInstagram />
            </IconButton>
          </a>
        </li>
      </ul>
    </nav>
  );
}

function DesktopHeader() {
  return (
    <div className="desktop-header">
      <Link to="/">
        <h1>Ryan Kaplan</h1>
      </Link>
      <Nav />
    </div>
  );
}

function MobileHeader() {
  const [navShown, setNavShown] = useState(false);

  let history = useHistory();
  history.listen(() => {
    setNavShown(false);
  });

  return (
    <div className="mobile-header">
      <h1>Ryan Kaplan</h1>
      <div onMouseDown={() => setNavShown(!navShown)}>
        <IconButton>
          <IconHamburger />
        </IconButton>
      </div>
      {navShown && (
        <div className="mobile-nav">
          <div className="x" onMouseDown={() => setNavShown(false)}>
            <IconButton>
              <IconX />
            </IconButton>
          </div>
          <Nav />
        </div>
      )}
    </div>
  );
}

class App extends React.Component {
  render() {
    return (
      <div className="page">
        <Router>
          <DesktopHeader />
          <MobileHeader />
          <Switch>
            <Route path="/about">
              <About />
            </Route>

            <Route path="/still-life">
              <GalleryGrid
                key="still-life"
                galleryRows={[
                  [grapes, peach],
                  [daisies, tissueBox, lemons],
                  [oranges, aromatics],
                ]}
              />
            </Route>
            <Route path="/landscape">
              <GalleryGrid
                key="landscape"
                galleryRows={[
                  [watermelons, lion],
                  [rockawayBeach, lakePowell, mtDavidson],
                ]}
              />
            </Route>
            <Route path="/">
              <GalleryGrid
                key="still-life"
                galleryRows={[
                  [grapes, peach],
                  [daisies, tissueBox, lemons],
                  [oranges, aromatics],
                ]}
              />
            </Route>
          </Switch>
        </Router>
        <footer className="page-footer">
          &copy; {new Date().getFullYear()} Ryan Kaplan
        </footer>
      </div>
    );
  }
}

export default App;
