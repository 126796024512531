import React, { useState, useRef, ReactElement } from "react";
import { MAX_WIDTH, ResizeObserver, MOBILE_BREAK } from "./App";

// src, lightbox src (with watermark), title, description
type GalleryImageTitle = string | ReactElement;
export type GalleryImage = {
  src: string;
  // Same as src but image has watermark
  lightboxSrc?: string;
  title?: GalleryImageTitle;
  desc?: string;
};

type GalleryRowProps = {
  items: GalleryImage[];
  imageClicked: (itemIndex: number) => void;
};

export function GalleryRow(props: GalleryRowProps) {
  const images = props.items;
  const refs: React.MutableRefObject<HTMLImageElement | null>[] = [];
  for (let i = 0; i < images.length; i++) {
    refs[i] = useRef<HTMLImageElement | null>(null);
  }

  const [imageWidths, setImageWidths] = useState<number[]>([]);
  const gridPadding = 20 * (images.length - 1);

  const onResize = () => {
    if (!refs.every((ref) => ref.current?.complete)) {
      return;
    }

    let windowWidth = document.documentElement.clientWidth;
    const pagePadding = 40;
    if (windowWidth > MAX_WIDTH) {
      windowWidth = MAX_WIDTH;
    }
    windowWidth -= 2 * pagePadding;
    windowWidth -= gridPadding;

    const newImageWidths = Array(images.length);

    // Step 1: resize all images to match height of first image
    let totalWidth = 0;
    let firstHeight = refs[0].current?.naturalWidth;

    refs.forEach((ref, i: number) => {
      const image = ref.current;
      if (image == null || firstHeight == null) {
        return;
      }

      const newWidth = (firstHeight * image.naturalWidth) / image.naturalHeight;
      newImageWidths[i] = newWidth;
      totalWidth += newWidth;
    });

    // Step 2: resize all images to fit in the window
    const resizeRatio = windowWidth / totalWidth;
    refs.forEach((_ref, i: number) => {
      newImageWidths[i] = newImageWidths[i] * resizeRatio;
    });

    setImageWidths(newImageWidths);
  };

  React.useLayoutEffect(() => {
    try {
      const resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(document.documentElement);
      return () => resizeObserver.disconnect();
    } catch {
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    }
  }, []);

  return (
    <div className="gallery-row">
      {images.map((item: GalleryImage, itemIndex: number) => {
        return (
          <div key={itemIndex} className="gallery-item">
            <picture>
              <source
                srcSet={item.lightboxSrc}
                media={`(max-width: ${MOBILE_BREAK}px)`}
              />
              <img
                key={itemIndex}
                src={item.src}
                width={imageWidths[itemIndex] || 0}
                ref={refs[itemIndex]}
                onLoad={onResize}
                onMouseDown={() => props.imageClicked(itemIndex)}
              />
            </picture>
            <div className="gallery-label">
              <div className="gallery-item-title">{item.title}</div>
              <div className="gallery-item-desc">{item.desc}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
